<template>
  <v-card class="mx-auto" :disabled="tbl_ldg">
    <v-card-title>
      <v-row dense>
        <v-col cols="12" md="8">
          <span
            class="text-h6 ml-1"
            v-text="
              $route.meta.title +
              (tbl_ldg || !tbl_data.length ? '' : ' (' + tbl_data.length + ')')
            "
          />
        </v-col>
        <v-col cols="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <v-tooltip v-if="login.role_id == 1" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="teal"
                :loading="btn_xls_ldg"
                :disabled="tbl_data.length == 0"
                @click.prevent="toXls"
              >
                <v-icon> mdi-table-arrow-down </v-icon>
              </v-btn>
            </template>
            <span v-text="'Descargar tabla XLS'" />
          </v-tooltip>
          <v-tooltip v-if="login.permissions.restitutions.create" bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="success"
                :to="{
                  name: 'restitutions.create',
                }"
              >
                <v-icon> mdi-plus </v-icon>
              </v-btn>
            </template>
            <span v-text="'Crear'" />
          </v-tooltip>
        </v-col>
        <v-col cols="12" md="3">
          <DatePicker
            :label="'Inicial'"
            :model.sync="start_date"
            :disabled="tbl_data.length != 0"
          />
        </v-col>
        <v-col cols="12" md="3">
          <DatePicker
            :label="'Final'"
            :model.sync="end_date"
            :disabled="tbl_data.length != 0"
          />
        </v-col>
        <v-col cols="12" md="3">
          <v-select
            :disabled="tbl_data.length != 0"
            label="Tipo"
            v-model="is_rha"
            :items="is_rha_opt"
            item-value="id"
            :item-text="(v) => v.name"
            dense
          />
        </v-col>
        <v-col cols="12" md="3">
          <v-select
            :disabled="tbl_data.length != 0"
            label="Estado"
            v-model="is_paid"
            :items="is_paid_opt"
            item-value="id"
            :item-text="(v) => v.name"
            dense
          />
        </v-col>
        <v-col cols="12" md="3">
          <v-radio-group
            :disabled="tbl_data.length != 0"
            v-model="contractor_brand"
            dense
            row
            class="mt-0"
          >
            <v-radio label="Concentrador" value="1" />
            <v-radio label="Contratante" value="0" />
          </v-radio-group>
        </v-col>
        <v-col v-if="contractor_brand == 0" cols="12" md="3">
          <v-autocomplete
            :disabled="tbl_data.length != 0"
            label="Nombre"
            v-model="contractor_id"
            :items="contractors"
            :loading="contractors_ldg"
            item-value="id"
            :item-text="(v) => v.name"
            dense
          />
        </v-col>
        <v-col v-else cols="12" md="3">
          <v-autocomplete
            :disabled="tbl_data.length != 0"
            label="Nombre"
            v-model="contractor_brand_id"
            :items="contractor_brands"
            :loading="contractor_brands_ldg"
            item-value="id"
            :item-text="(v) => v.contractor_brand"
            dense
          />
        </v-col>
        <v-col v-if="login.role_id == 1" cols="12" md="3">
          <v-select
            :disabled="tbl_data.length != 0"
            label="Ver"
            v-model="active"
            :items="active_opt"
            item-value="id"
            :item-text="(v) => v.name"
            dense
          />
        </v-col>
        <v-col v-if="tbl_data.length == 0" cols="12">
          <v-btn
            block
            small
            color="success"
            @click.prevent="getTblData"
            :loading="tbl_ldg"
            :disabled="is_rha == null || is_paid == null"
          >
            Buscar
            <v-icon right> mdi-magnify </v-icon>
          </v-btn>
        </v-col>
        <v-col v-else cols="12">
          <v-btn block small color="info" @click.prevent="tbl_data = []">
            Cambiar parametros
            <v-icon right> mdi-refresh </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" sm="12" md="4">
          <ViewData
            label="Monto"
            :value="general ? numberFormat(general.amount) : ''"
          />
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <ViewData label="Registros" :value="general ? tbl_data.length : ''" />
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <v-text-field
            v-model="tbl_search"
            append-icon="mdi-magnify"
            label="Buscar..."
            single-line
            hide-details
            dense
            :disabled="tbl_data.length == 0"
          />
        </v-col>
        <v-col cols="12">
          <v-data-table
            :headers="tbl_headers"
            :search="tbl_search"
            :items="tbl_data"
            :loading="tbl_ldg"
            :items-per-page="15"
            dense
          >
            <template v-slot:item.index="{ item, index }">
              <b v-text="index + 1" />
            </template>
            <template v-slot:item.folio="{ item }">
              <span :class="item.active ? '' : 'red--text'">{{
                item.folio
              }}</span>
            </template>
            <template v-slot:item.amount="{ item }">
              {{ numberFormat(item.amount) }}
            </template>
            <template v-slot:item.paid_date="{ item }">
              {{ item.paid_date ? item.paid_date : "NO REG." }}
            </template>
            <template v-slot:item.action="{ item }">
              <v-tooltip left>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="mr-1"
                    v-on="on"
                    icon
                    small
                    color="info"
                    :to="{
                      name: 'restitutions.show',
                      params: { id: item.id },
                    }"
                  >
                    <v-icon small> mdi-eye </v-icon>
                  </v-btn>
                </template>
                <span v-text="'Ver detalle'" />
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  URL_DOCUMENTS,
  msgAlert,
  base64ToArrayBuffer,
  dateTimeToFile,
  dateTimeNow,
} from "../../control";
import FaqDlg from "../../components/FaqDlg.vue";
import DatePicker from "../../components/DatePicker.vue";
import ViewData from "../../components/ViewData.vue";

export default {
  components: {
    FaqDlg,
    DatePicker,
    ViewData,
  },
  data() {
    return {
      url_documents: URL_DOCUMENTS,
      login: this.$store.getters.getLogin,
      tbl_ldg: false,
      tbl_data: [],
      tbl_search: "",
      tbl_headers: [
        {
          value: "index",
          text: "#",
          filterable: false,
          width: "60",
        },
        {
          value: "folio",
          text: "Restitución",
          filterable: true,
        },
        {
          value: "type",
          text: "Tipo",
          filterable: true,
        },
        {
          value: "created_at",
          text: "Creación",
          filterable: true,
        },
        {
          value: "created_by.email",
          text: "Creado por",
          filterable: true,
        },
        {
          value: "contractor.name",
          text: "Contratante",
          filterable: true,
        },
        {
          value: "stamp_folio",
          text: "Folio Fact.",
          filterable: true,
        },
        {
          value: "amount",
          text: "Monto",
          filterable: true,
        },
        {
          value: "restitution_items",
          text: "Registros",
          filterable: true,
        },
        {
          value: "paid_date",
          text: "F. pago",
          filterable: true,
        },
        {
          value: "observation",
          text: "Observación",
          filterable: true,
        },
        {
          value: "action",
          text: "",
          sortable: false,
          filterable: false,
          width: "160",
        },
      ],
      general: null,
      bill: null,
      start_date: null,
      end_date: null,
      is_rha: null,
      is_rha_opt: [
        {
          id: 0,
          name: "S. MÉDICOS",
        },
        {
          id: 1,
          name: "S. HOSPITALARIOS",
        },
        {
          id: 2,
          name: "REEMBOLSOS",
        },
        {
          id: 3,
          name: "TODOS",
        },
      ],
      is_paid: null,
      is_paid_opt: [
        {
          id: 0,
          name: "NO PAGADOS",
        },
        {
          id: 1,
          name: "PAGADOS",
        },
      ],
      active: 1,
      active_opt: [
        {
          id: 1,
          name: "ACTIVOS",
        },
        {
          id: 0,
          name: "ELIMINADOS",
        },
      ],
      contractor_id: null,
      contractors: [],
      contractors_ldg: true,
      contractor_brand: "1",
      contractor_brand_id: null,
      contractor_brands: [],
      contractor_brands_ldg: true,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
      btn_xls_ldg: false,
    };
  },
  methods: {
    numberFormat(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },

    getTblData() {
      this.tbl_ldg = true;
      this.general = null;

      Axios.get(
        URL_API +
          "/restitutions" +
          "?start_date=" +
          this.start_date +
          "&end_date=" +
          this.end_date +
          "&is_rha=" +
          this.is_rha +
          "&is_paid=" +
          this.is_paid +
          "&contractor_brand=" +
          this.contractor_brand +
          "&contractor_id=" +
          this.contractor_id +
          "&contractor_brand_id=" +
          this.contractor_brand_id +
          "&active=" +
          this.active,
        headersToken(this.login.token)
      ).then((res) => {
        this.tbl_data = res.data.data.items;
        this.general = res.data.data.general;
        this.tbl_ldg = false;
      });
    },

    toXls() {
      this.btn_xls_ldg = true;

      Axios.post(
        URL_API + "/restitutions/toxlsx",
        {
          items: this.tbl_data,
        },
        headersToken(this.login.token)
      ).then((rsp) => {
        if (rsp.data.success) {
          const blob = new Blob([base64ToArrayBuffer(rsp.data.data)], {
            type: "application/xlsx",
          });
          let downloadLink = document.createElement("a");
          downloadLink.setAttribute("target", "_blank");
          downloadLink.href = URL.createObjectURL(blob);
          downloadLink.download =
            "SM_restituciones_" + dateTimeToFile() + ".xlsx";
          downloadLink.click();

          this.btn_xls_ldg = false;
        } else {
          this.$swal.fire(msgAlert("error", "Error al generar archixo XLSX"));
        }

        this.btn_xls_ldg = false;
      });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((res) => {
      this.faqs = res.data.data;
      this.faqs_ldg = false;
    });

    Axios.get(URL_API + "/contractors", headersToken(this.login.token)).then(
      (res) => {
        this.contractors = res.data.data;
        this.contractors.push({
          id: null,
          name: "TODOS",
        });
        this.contractors_ldg = false;
      }
    );

    Axios.get(
      URL_API + "/contractors/brands",
      headersToken(this.login.token)
    ).then((res) => {
      this.contractor_brands = res.data.data;
      this.contractor_brands.push({
        id: null,
        contractor_brand: "TODOS",
      });
      this.contractor_brands_ldg = false;
    });

    this.start_date = dateTimeNow().substring(0, 8) + "01";
    this.end_date = dateTimeNow().substring(0, 10);
  },
};
</script>